<template>
  <span v-if="stats && 'messages_count' in stats">
    <badge title="Total">{{ stats.messages_count }}</badge>
    <badge title="Sent" color="green">{{ stats.messages_sent }}</badge>
    <badge title="Undelivered" color="yellow">{{ stats.messages_undelivered }}</badge>
    <badge title="Failed" color="red">{{ stats.messages_failed }}</badge>
    <badge title="Blacklisted" color="black">{{ stats.messages_blacklisted }}</badge>
  </span>
  <loader v-else/>
</template>

<script>
import Badge from '@/views/components/simple/Badge'
import Loader from '@/views/components/simple/Loader'

export default {
  name: 'StatsMessages',
  components: {
    Loader,
    Badge
  },
  props: {
    stats: { type: Object, required: true }
  }
}
</script>
