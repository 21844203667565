<template>
  <badge :color="color" :title="title">{{ title }}</badge>
</template>

<script>
import Badge from '@/views/components/simple/Badge'
import Status from '@/modules/utils/Status'

export default {
  name: 'BadgeStatus',
  components: {
    Badge
  },
  props: {
    value: { type: String, required: true }
  },
  computed: {
    title () { return this.$helpers.Str.upperFirst(this.value) },
    color () { return Status.color(this.value) }
  }
}
</script>
