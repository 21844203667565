const Color = {
  gray: 'gray',
  green: 'green',
  yellow: 'yellow',
  red: 'red',
  black: 'black',
  maroon: 'maroon',
  aqua: 'aqua',
  blue: 'blue',
  lightBlue: 'light-blue'
}

export default Color
