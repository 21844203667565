<template>
  <loader v-if="!loaded || !stats"/>
  <div class="td-rows columns divider" v-else>
    <slot name="pre-rows"/>
    <div class="td-row">
      <span class="left">Messages:</span>
      <stats-messages class="right" :stats="stats"/>
    </div>
    <div class="td-row">
      <span class="left">Clicks:</span>
      <stats-clicks class="right" :stats="stats"/>
    </div>
    <div class="td-row">
      <span class="left">Revenues:</span>
      <stats-revenues class="right" :stats="stats"/>
    </div>
    <slot name="post-rows"/>
  </div>
</template>

<script>
import Loader from '@/views/components/simple/Loader'
import StatsMessages from '@/views/components/stats/Messages'
import StatsClicks from '@/views/components/stats/Clicks'
import StatsRevenues from '@/views/components/stats/Revenues'

export default {
  name: 'StatsColumn',
  components: {
    Loader,
    StatsMessages,
    StatsClicks,
    StatsRevenues
  },
  props: {
    loaded: { type: Boolean, default: true },
    stats: { type: Object, default: undefined }
  }
}
</script>
