<template>
    <span class="loader-dots">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </span>
</template>

<script>
export default {
  name: 'Loader'
}
</script>

<style lang="scss">
$color: #367fa9;
.loader-dots {
  padding: 0;
  .td-rows.columns & {
    padding: 6px 0;
  }
  span {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $color;
    opacity: 1;
    animation: loader-dots 1s infinite ease-in-out;
    &:not(:first-child) {
      margin-left: 4px;
    }
    &:nth-child(1) {
      animation-delay: 0.2s;
    }
    &:nth-child(2) {
      animation-delay: 0.3s;
    }
    &:nth-child(3) {
      animation-delay: 0.4s;
    }
    &:nth-child(4) {
      animation-delay: 0.5s;
    }
    &:nth-child(5) {
      animation-delay: 0.6s;
    }
  }
}
@keyframes loader-dots {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}
</style>
