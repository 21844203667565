<template>
  <div>
    <portal to="page-top-title">Lists</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'contactLists-new' }" icon="fa-plus" text>Add New</btn>
    </portal>

    <box-table title="List" url="/contact-lists" :url-query="{ with: ['category:id,name'] }" data-prop="lists"
               :dataCallback="dataCallback" loader @request-after="requestAfter">
      <template slot="table-header">
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Category</th>
          <th>Price</th>
          <th>Custom ID</th>
          <th class="text-center">Live</th>
          <th class="text-center">Active</th>
          <th class="text-center">Status</th>
          <th>Stats</th>
          <th class="text-right">Created At</th>
          <th class="text-right"></th>
        </tr>
      </template>
      <template slot="table-body">
        <table-row v-for="list in lists" :key="`lists-tr-${list.id}`" :value="list">
          <td>{{ list.id }}</td>
          <td>{{ list.name }}</td>
          <td>
            <router-link :to="{ name: 'contactLists-categories-single', params: { id: list.category.id } }">{{ list.category.name }}</router-link>
          </td>
          <td>{{ priceFormatter.format(list.price) }}</td>
          <td>
            <code>{{ list.custom_id }}</code>
          </td>
          <td class="text-center">
            <badge-active :value="list.live_list"/>
          </td>
          <td class="text-center">
            <badge-active :value="list.active"/>
          </td>
          <td class="text-center">
            <badge-status :value="list.status_text"/>
          </td>
          <td>
            <stats-column :stats="list.stats">
              <div class="td-row" slot="pre-rows">
                <span class="left">Contacts:</span>
                <span class="right">
                  <badge title="Total">{{ list.contacts_count_total }}</badge>
                  <badge title="Active" color="green">{{ list.contacts_count_active }}</badge>
                  <badge title="Inactive" color="red">{{ list.contacts_count_inactive }}</badge>
                </span>
              </div>
            </stats-column>
          </td>
          <td class="text-right">{{ list.created_at }}</td>
          <td class="text-right">
            <btn :route="{ name: 'contactLists-edit', params: { id: list.id } }" icon="fa-edit" v-if="list.metadata.editable.this">Edit</btn>
            <btn :route="{ name: 'contactLists-single', params: { id: list.id } }" color="info" icon="fa-eye">Show</btn>
          </td>
        </table-row>
      </template>
    </box-table>
  </div>
</template>

<script>
import BoxTable from '@/views/components/data/BoxTable'
import Btn from '@/views/components/simple/Btn'
import Badge from '@/views/components/simple/Badge'
import BadgeActive from '@/views/components/simple/BadgeActive'
import BadgeStatus from '@/views/components/simple/BadgeStatus'
import StatsColumn from '@/views/components/stats/StatsColumn'

export default {
  name: 'ContactListsList',
  metaInfo: {
    title: 'Lists'
  },
  components: {
    BoxTable,
    Btn,
    Badge,
    BadgeActive,
    BadgeStatus,
    StatsColumn
  },
  data () {
    return {
      lists: [],
      priceFormatter: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })
    }
  },
  methods: {
    dataCallback (data) {
      return data.map((item) => { item.stats = undefined; return item })
    },
    requestAfter () {
      this.loadContactListStats(
        this.lists.map((list) => list.id)
      )
    },
    loadContactListStats (ids) {
      this.$http
        .get('/contact-lists/stats', { params: { ids: ids } })
        .then((res) => {
          const { data: { data } } = res

          this.lists.forEach((list) => {
            list.stats = data.find((stats) => stats.id === list.id)
          })
        })
    }
  }
}
</script>
