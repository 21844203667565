<template>
  <span v-if="stats && 'clicks_count' in stats">
    <badge title="Percentage" color="light-blue">{{ clicksPercentage }}</badge>
    <badge title="Count" color="aqua-active">{{ stats.clicks_count }}</badge>
  </span>
  <loader v-else/>
</template>

<script>
import Badge from '@/views/components/simple/Badge'
import Loader from '@/views/components/simple/Loader'
import Stats from '@/modules/utils/Stats'

export default {
  name: 'StatsClicks',
  components: {
    Loader,
    Badge
  },
  props: {
    stats: { type: Object, required: true }
  },
  data () {
    return {
      formatter: new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    }
  },
  computed: {
    clicksPercentage () {
      const messagesTotal = this.stats.messages_sent + this.stats.messages_undelivered
      const percentage = Stats.getClicksPercentage(this.stats.clicks_count, messagesTotal)

      return this.formatter.format(percentage / 100)
    }
  }
}
</script>
