import Color from '@/modules/utils/Color'
import Str from '@/modules/helpers/str'

const statuses = {
  fresh: Color.gray,
  draft: Color.gray,

  sent: Color.green,
  parsed: Color.green,
  ready: Color.green,

  locked: Color.yellow,
  paused: Color.yellow,
  pending: Color.yellow,

  error: Color.red,
  failed: Color.red,
  canceled: Color.red,

  archived: Color.black,

  swept: Color.maroon,
  scheduled: Color.aqua,
  sending: Color.lightBlue
}
const color = function (value) {
  return statuses[Str.camelCase(value)] || Color.gray
}

const Status = {
  color
}

export default Status
