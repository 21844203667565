<template>
  <span v-if="stats && 'revenues_count' in stats">
    <badge title="Amount" color="light-blue">{{ formatter.format(stats.revenues_amount) }}</badge>
    <badge title="Count" color="aqua-active">{{ stats.revenues_count }}</badge>
  </span>
  <loader v-else/>
</template>

<script>
import Badge from '@/views/components/simple/Badge'
import Loader from '@/views/components/simple/Loader'

export default {
  name: 'StatsRevenues',
  components: {
    Loader,
    Badge
  },
  props: {
    stats: { type: Object, required: true }
  },
  data () {
    return {
      formatter: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })
    }
  }
}
</script>
